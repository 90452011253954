.icon.pixel {
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background: url('../../icons/providers/default.svg');
  flex-shrink: 0;
}

.icon.pixel.fb {
  background: url('../../icons/providers/fb.svg');
}

.icon.pixel.lk {
  background: url('../../icons/providers/lk.svg');
}

.icon.pixel.pin {
  background: url('../../icons/providers/pin.svg');
}

.icon.pixel.twt {
  background: url('../../icons/providers/twt.svg');
}

.icon.pixel.glg {
  background: url('../../icons/providers/glg.svg');
}

.icon.pixel.tkt {
  background: url('../../icons/providers/tkt.svg');
}

.icon.pixel.ga {
  background: url('../../icons/providers/ga.svg');
}

.icon.pixel.qr {
  background: url('../../icons/providers/qr.svg');
}

.icon.pixel.gtm {
  background: url('../../icons/providers/gtm.svg');
}

.icon.pixel.bing {
  background: url('../../icons/providers/bing.svg');
}

.icon.pixel.nxs {
  background: url('../../icons/providers/nxs.svg');
}
