.username-edit__main_container {
  padding: 1rem;
  display: flex;
}

.username-edit__input_container {
  width: 50%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 88vh;
}

.username-edit__input_container span {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.username-edit__input_container h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.username-edit__input_container h2 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #5c5c5c;
  text-align: center;
  /* margin-top: 1rem; */
}
.username-edit__input_container p {
  margin-top: 2rem;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6d859e;
}

.username-edit__picture_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-edit__input {
  width: 281px;
  height: 32px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
}

.user-edit__input input {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: none;
  padding: 0;
  margin: 0;
  padding-bottom: 2px;
  padding-left: 2px;
}

.user-edit__input input::placeholder {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #e5e5e5;
}
