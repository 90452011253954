.pixels-list {
  display: flex;
  color: var(--text-muted);
  align-items: center;
}
.pixels-list > * {
  margin-left: 0.25rem;
}
/* .pixels-list > *:first-child {
  margin-left: 0;
} */
