.content {
  width: 100%;
  padding: 10px 15px 10px 15px; /* 0 hack for scroll and padding */
  --content-margin: 2rem;
  display: grid;
  grid-template-rows: auto 1fr;
  color: var(--text-color);
  height: 100%;
  border-top: var(--separator);
}

/* hack for scroll and padding */
.content > :last-child {
  margin-bottom: 20px;
}

.content__breadcrumb,
.content__submenu {
  display: flex;
  background: var(--white);
  padding: 0px var(--content-margin);
  align-items: center;
}

.content__breadcrumb {
  border-bottom: var(--border-size) solid #e5e5e5;
}

.content__breadcrumb > ul,
.content__submenu > ul {
  flex-grow: 1;
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.content__breadcrumb > ul,
.content__submenu > ul a {
  --border-width: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%;  This actually only works if a button is present on the line, so I switched to a Magic Number (TM) instead */
  height: 34px;
  margin: 0;
  border-bottom: var(--border-width) solid var(--white);
  color: var(--text-muted);
  font-size: 13px;
}

.content__breadcrumb > ul {
  justify-content: left;
}

.content__submenu > ul > li {
  margin: 0px 1rem;
}

.content__submenu > ul > li:first-child {
  margin: 0px 1rem 0px 0px;
}

.content__submenu > ul a:hover {
  border-bottom: var(--border-width) solid var(--pixelme-color);
  text-decoration: none;
}

.content__submenu > ul a.current {
  border-bottom: var(--border-width) solid var(--pixelme-color);
  color: var(--text-color);
}
