.invoices .placeholder {
  margin-top: 1rem;
}

.invoices .placeholder__line {
  width: 300px;
}

.invoices .placeholder:first-child {
  margin-top: 0.5rem;
}

.invoices.upcoming-invoice {
  margin-top: 1rem;
}

.invoice:nth-child(even) {
  background-color: #f9f9f9;
}

.invoice {
  color: var(--text-muted);
  display: flex;
  padding: 0.5rem 0;
}

.invoice__number {
  flex: 0 0 100px;
}

.invoice__period {
  flex: 0 0 100px;
}

.invoice__amount {
  flex: 1 1;
}
