.editlink__container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 1rem;
}

.editlink {
  padding-right: 1rem;
  margin-top: 1rem;
}

.editlink input {
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: #071629;
}

.editlink span {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #071629;
}

.editlink textarea {
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  width: 100%;
  resize: none;
  height: 72px;
  padding: 0 0.8rem;
  padding-top: 0.5rem;
}

.editlink img {
  height: 208px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.preview-container {
  position: relative;
  width: 100%;
}

.preview-overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgba(130, 130, 130, 0.8);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  cursor: pointer;
  border-radius: 4px;
}

.preview-container:hover .preview-overlay {
  bottom: 0;
  height: 100%;
}

.preview-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  flex-direction: column;
}

.preview-text img {
  width: 18px;
  height: 18px;
}

.preview-text h2 {
  font-size: 12px;
  color: #ffffff;
}

.button-edit-preview {
  width: 172px;
  height: 35px;
  border: 1px solid #2e89e2;
  border-radius: 3px;
  background-color: white;
  font-weight: 600;
  text-align: center;
  color: #2e89e2;
  cursor: pointer;
}

.button-edit-preview:disabled,
.button-edit-preview[disabled] {
  opacity: 0.5;
}
