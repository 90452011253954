.pix-button {
  height: fit-content;
  border-radius: 0.2rem;
  padding: 0.5rem 0.8rem;
  min-width: 4.5rem;
  text-align: center;
  font-weight: lighter;
  height: 100%;
}

.pix-button:hover {
  cursor: pointer;
}

.pix-button:disabled {
  cursor: not-allowed;
}

.pix-button--light {
  background: var(--white);
  border: var(--input-border-color);
  color: var(--pixelme-color);
  font-weight: 500;
}

.pix-button--light:hover {
  border: var(--input-border-color);
  color: var(--white);
  background: var(--pixelme-color);
}

.pix-button--light:disabled {
  color: var(--text-muted);
  border: var(--input-border);
}

.pix-button--normal {
  background: var(--pixelme-color);
  color: var(--white);
  border: none;
}

.pix-button--normal:hover {
  background: #0e5396;
  color: var(--white);
}

.pix-button--normal:disabled {
  background: #c0dcf6;
  color: var(--white);
}

.pix-button--cancel {
  background: transparent;
  color: var(--bad-color);
  border: none;
}

.pix-button--cancel:hover {
  text-decoration: underline;
}

.pix-button--delete {
  background: var(--white);
  color: var(--text-muted);
  border: var(--input-border);
}

.pix-button--delete:hover {
  color: var(--bad-color);
  border-color: var(--bad-color);
}
/*
.pix-button--cancel:disabled {
  background: #c0dcf6;
  color: var(--white);
} */

.pix-button--locked {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 1rem;
  background: #c0dcf6;
  color: var(--white);
  border: none;
}

.pix-button--locked > svg {
  position: relative;
  top: 1px;
  margin-right: 6px;
}
