.usage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1 18rem;
  height: 100%;
  padding: 1rem 0;
  font-size: var(--small-size);
  margin-right: 30px;
}

.usage--empty {
  flex-direction: row;
  flex: 0 1 18rem;
  height: 100%;
  padding: 1rem 0;
  font-size: var(--small-size);
  > .usage__subtitle {
    align-items: center;
  }
}

.usage__subtitle {
  color: var(--text-muted);
}

.usage--empty > .usage__subtitle > span {
  margin-right: 0.5rem;
}

.progress-bar {
  --radius: 0.5rem;
  display: flex;
  width: 260px;
  height: 10px;
  margin-bottom: 6px;
  border-radius: var(--radius);
  background: var(--bg-color);
}

.progress-bar__value {
  width: 100%;
  background: var(--pixelme-color);
  border-radius: var(--radius) 0 0 var(--radius);
}

.progress-bar__value--full {
  border-radius: var(--radius);
  background: var(--bad-color);
}

.usage__subtitle {
  display: flex;
  justify-content: space-between;
}
