.ant-table-row:hover .floating-row-action,
.ant-table-cell-row-hover .floating-row-action {
  margin-left: 0;
  position: relative;
}
.ant-table-row:hover .floating-row-action-container,
.ant-table-cell-row-hover .floating-row-action-container {
  opacity: 1;
  width: fit-content;
  height: 100%;
  top: 0;
  padding-right: 1em;
  padding-left: 1em;
  border-left: 1px solid #eee;
  box-shadow: -33px 2px 11px 2px #ffffff;
}

.ant-table-row:hover
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--pixelme-color);
  transition: background-color 0.5s ease;
}
