.import__container > * {
  margin: 1rem 0 0 0;
}

.import__container textarea {
  max-width: 584px;
  height: 200px;
  line-height: 20px;
}

.import__dropdowns {
  display: flex;
}

.import__dropdowns > * {
  margin-right: 1rem;
}

.import__dropdowns .drop-down__container {
  margin-top: 0.5rem;
}

.import__container .modal-mask {
  margin: 0;
}
.import__container .modal.box {
  width: 400px;
}
.import__modal__header {
  font-size: var(--title-size);
}
