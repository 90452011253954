.team-members > .box {
  margin-top: 1rem;
}
.team-members > .box:first-child {
  margin-top: 0;
}

.team-members__add form {
  display: flex;
  margin: 0.5rem 0;
}

.team-members__add form > * {
  margin-right: 1rem;
  height: 1.8rem;
}

.team-members__add form input {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.5rem;
}

.team-members__add form button {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 1rem;
}

.team-members__add form > *:last-child {
  margin-right: 0;
}
input#fullname {
  min-width: 204px;
}

input#email {
  min-width: 236px;
}

.team-members__me {
  color: var(--text-muted);
}
.team-members__owner__row {
  margin: 0.5rem 0;
  display: flex;
}
.team-members__owner__email {
  margin-left: 3rem;
}
.team-members__modal__delete .modal.box {
  width: 800px !important;
  height: auto !important;
}

.team-members.box .settings-box__content .placeholder {
  margin-top: 1rem;
}
