.input-container {
  display: flex;
  flex-direction: column;
}

.input {
  width: auto;
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 0.5rem;
  margin: 0;
}

.input-label {
  margin-bottom: 0.5rem;
}

textarea.input {
  resize: none;
}
