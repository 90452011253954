@media screen and (max-height: 900px) {
  .with--dropdown .modal__content-under-title {
    height: 85vh;
  }
}
.error-modal-mask {
  position: fixed;
  top: 0;
  left: 4rem;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: auto;
  background: rgba(255, 255, 255, 0.8);
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: auto;
  background: rgba(255, 255, 255, 0.8);
}

.modal.box {
  z-index: 101;
  width: auto;
  margin: auto;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  box-shadow: 0px 0px 4px 4px #eeeeee;
  position: relative;
}

.modal__content {
  padding: 2rem;
  text-align: center;
  text-overflow: ellipsis;
}

.modal__content-under-title {
  padding: 0 1rem 1rem;
  width: 100%;
  /*max-height: 80vh;*/
  overflow-y: auto;
}

.modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__button {
  height: 2rem;
  width: auto;
  margin: auto;
  padding: 0.5rem 3rem;
}

.modal__title__container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.modal__title {
  height: 47px;
  padding: 1rem;
  flex: 1;
}

.modal__close-button {
  width: 10px;
  margin-right: 25px;
  display: inline-block;
  align-self: center;
  align-content: center;
  align-items: center;
}
