.sub-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-size);
}

.sub-checkout__content > * {
  margin-bottom: 1rem;
}
.sub-checkout__content > *:last-child {
  margin-bottom: 0;
}

.sub-checkout__content {
  min-width: 300px;
}

.sub-checkout__plan-title {
  text-align: center;
  font-size: 18px;
}

.sub-checkout__buttons {
  display: flex;
  justify-content: space-between;
}

.sub-checkout .credit-card-checkout {
  font-size: var(--small-size);
}

.sub-checkout .button--cancel {
  padding-left: 0;
  text-align: left;
}

.sub-checkout__message {
  margin-top: 1rem;
  text-align: center;
}