.main-layout button,
.main-layout input,
.main-layout optgroup,
.main-layout select,
.main-layout textarea {
  line-height: normal;
}
:focus {
  outline-offset: -2px !important;
  outline: var(--pixelme-color) auto 2px;
}

::placeholder {
  color: rgb(219, 219, 219) !important;
  font-size: 12px !important;
}

html {
  overflow-y: hidden;
}

html,
span,
div {
  line-height: normal;
}

body {
  font-family: var(--font-family) !important;
  font-size: var(--normal-size);
  font-weight: var(--main-weight);
}

button:focus,
.search-redirects__search:hover,
.search-redirects__search:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--pixelme-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* img {
  margin: auto;
} */

.box,
.mini-box {
  background: var(--white);
  padding: 1rem;
  /* border-radius: 0.25rem; */
  /* box-shadow: 0px 4px 2px 2px #eeeeee; */
  min-width: 0; /* for grid layout */
}

.mini-box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::placeholder {
  font-weight: lighter;
  color: var(--text-muted);
}

.grid > * {
  overflow: hidden;
}

.text-muted {
  color: var(--text-muted);
}

.text-pixelme {
  color: var(--pixelme-color);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bp3-portal {
  z-index: 800;
}

.bp3-select-popover .bp3-menu,
.bp3-select-popover .bp3-popover-content {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important;
  text-overflow: ellipsis;
  text-align: left;
}

.side-modal__content .bp3-button {
  font-size: 12px;
  padding: 5px 10px;
}

.bp3-input:not(.bp3-active) {
  box-shadow: none !important;
}

.bp3-add-option,
.bp3-add-option span,
.bp3-add-option div {
  color: var(--pixelme-color) !important;
}

.bp3-input-group input {
  font-size: 12px !important;
}

.bp3-tag-input {
  padding-left: 0 !important;
}

.bp3-tag-input .bp3-tag-input-values {
  margin-right: 0 !important;
}

.bp3-datepicker * {
  font-size: 11px !important;
}

.bp3-html-select select,
.bp3-select select {
  font-size: 12px !important;
}

.bp3-spinner .bp3-spinner-head {
  stroke: var(--pixelme-color) !important;
}

.notice__message {
  color: #333 !important;
  font-size: 12px !important;
}

h1 {
  color: #000;
  font-size: 25px;
  margin-bottom: 1em;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  color: #262626;
}
.ant-table-cell {
  color: #595959;
  font-weight: 400;
  font-size: 13px;
  padding: 8px;
}
.ant-statistic-title {
  margin-left: 1em;
  margin-right: 1em;
}

.ant-breadcrumb,
.ant-breadcrumb > span {
  display: flex;
  align-items: center;
}

.ant-breadcrumb-link {
  white-space: nowrap;
}

.ant-empty-image {
  zoom: 0.5;
}

table {
  background: white;
}

.ant-form-item {
}

.ant-modal {
  max-width: calc(100vw);
}

.ant-tag {
  margin-top: 3px;
}

.ant-input-lg {
  height: 40px;
}
.ant-select-selection-item-content {
  line-height: 30px;
}
.ant-select-selection-item-content,
.ant-select-item-option-content,
.ant-input,
.ant-input-number-input,
.ant-picker-large .ant-picker-input > input,
.ant-select-selection-placeholder,
.ant-form-item-label > label,
.ant-radio-group-large .ant-radio-button-wrapper,
.ant-radio-button-wrapper,
.ant-select-lg {
  font-size: 14px !important;
}

element.style {
  cursor: pointer;
}

.ant-table-wrapper {
  background-color: white;
}

.no-padding {
  padding: 0 !important;
}

.ant-table .ant-statistic-content-value,
.ant-table .ant-statistic-content-prefix {
  font-size: 13px !important;
  font-family: var(--font-family) !important;
}

.ant-table .ant-statistic-content {
  display: inline-flex;
}
.ant-select-selection-placeholder,
.ant-input ::placeholder,
.ant-input::placeholder,
input::placeholder {
  font-size: 14px !important;
  font-family: var(--font-family) !important;
  color: var(--text-muted) !important;
}

.amazon,
.amazon * {
  color: #f8a51b !important;
  fill: #f8a51b !important;
}

.small-provider svg {
  height: 20px;
  width: 20px;
}
.editable {
  background-color: var(--pixelme-light-color);
}

@media (min-width: 1280px) {
  .hide-desktop-show-mobile {
    display: none;
  }
}
@media (max-width: 1280px) {
  .show-desktop-hide-mobile {
    display: none;
  }
}

.ant-table-row:hover .steps-container {
  background: #fafafa;
}

.ant-table-row-selected .steps-container {
  background: #e6f7ff !important;
  border-color: rgba(0, 0, 0, 0.03);
}

.default-cursor {
  cursor: auto;
}

.row-empty td {
  border-right: none !important;
}

.row-out-of-limits td {
  border-right: rgba(0, 0, 0, 0.03) !important;
  background: rgba(253, 253, 253, 0.5) !important;
}

.row-active {
  background: #fafafa !important;
}

.ant-modal-confirm-title {
  margin-top: -15px;
}

.centered-btn .ant-modal-confirm-btns {
  float: none;
  text-align: center;
}

div.netsuite-popup {
  /* Hide below view and account for C6 navbar height */
  top: calc(100% + 48px);
}
div.netsuite-popup.active {
  top: 0;
}
