.list-view {
  display: flex;
  flex-direction: column;
}

.list-view__header {
  display: flex;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.list-view__header__title {
  flex: 1 1 auto;
}

.list-view__header__clicks {
  flex: 0 1 60px;
  text-align: right;
}

.list-view__header__uclicks {
  flex: 0 1 110px;
  text-align: right;
}

.list-view__content {
  display: flex;
  flex-direction: column;
}

.list-view__line {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
}

.list-view__line--odd {
  background: #f9f9f9;
}

.list-view__number {
  flex: 0 0 auto;
  padding-right: 0.25rem;
}

.list-view__label {
  flex: 1 1 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--pixelme-color);
}

.list-view__percent {
  padding-left: 5px;
  color: var(--text-muted);
}

.list-view__clicks {
  flex: 0 1 60px;
  text-align: right;
}

.list-view__uclicks {
  flex: 0 1 110px;
  text-align: right;
}
