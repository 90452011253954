.analytics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 4rem 16rem;
  grid-auto-rows: 28rem;
  grid-gap: 1rem;
  background: var(--bg-color);
  color: var(--text-color);
  height: 100%;
}

.analytics.analytics-details {
  grid-template-rows: 9rem 16rem;
}
.analytics__header {
  display: flex;
  /* height: 4rem; */
  align-items: center;
  grid-column-end: span 2;
}

.analytics__graph {
  grid-column-end: span 2;
}
