.search-redirects {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100%;
  z-index: 2;
}

.search-redirects .checkbox-container {
  flex: 0 0 0.8rem;
  height: 0.8rem;
  margin-right: 0.4rem;
  flex-shrink: 0;
  cursor: pointer;
}

.search-redirects__search {
  /* width: 100%; */
  /* height: 2rem; */
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 0.5rem;
  flex: 1;
  margin: 0 0.5rem 0 0;
}

.search-redirects__search:hover,
.search-redirects__search:focus {
  border: var(--input-border) !important;
  border-color: var(--pixelme-color) !important;
}

.search-redirects__sort {
  display: flex;
  align-items: center;
}

.search-redirects__sort > *:first-child {
  margin-right: 0.5rem;
}

.search-redirects__sort .drop-down__content {
  min-width: 0;
}

.search-redirects__dropdown {
  position: absolute;
  font-size: 12px;
  padding: 0 0.8rem;
  margin-top: 0.5rem;
  background: var(--white);
  border: var(--input-border);
  border-radius: 0.2rem;
  box-shadow: 0px 4px 2px 2px #eeeeee;
  z-index: 2;
}

.search-redirects__dropdown__line {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-top: var(--input-border);
  align-items: center;
}

.search-redirects__dropdown__line:last-child {
  justify-content: flex-end;
}

.search-redirects__dropdown__line:first-child {
  justify-content: flex-start;
  border-top: none;
}

.search-redirects__dropdown__line:first-child > * {
  margin-left: 1rem;
}

#search-redirects__dropdown__mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.search-redirects__subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: var(--text-muted);
  margin: 0.5rem 0 0 0;
}

.search-redirects__subtitle > a {
  color: var(--bad-color);
  cursor: pointer;
}

.search-redirects__dropdown__line--justify {
  justify-content: space-between;
}

.search-redirects__dropdown .consent {
  flex: 0 0 101px;
}

#cookie-consent .drop-down__content {
  min-width: 0;
}
