.gdpr .gdpr__form-group {
  margin: 1rem 0;
}

.gdpr .gdpr__form-group input {
  max-width: 400px;
}

.gdpr .gdpr__form-group > label {
  font-size: var(--normal-size);
}

.gdpr .gdpr__field {
  padding: 0.5rem 0;
  margin-top: 0.25rem;
  height: 2rem;
}

.gdpr .drop-down__container {
  margin-top: 0.5rem;
}

.gdpr .gdpr__field.input-container {
  padding: 0.25rem 0;
}
.gdpr .gdpr__buttons {
  margin-top: 2rem;
}
