.top {
  display: flex;
  background: var(--white);
  color: var(--text-color);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.top__title {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
}
.top__title__label {
  font-size: 20px;
  font-weight: 200;
  margin-right: 30px;
  position: relative;
  top: 3px;
}

.top__sub-menu {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
