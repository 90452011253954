.switch-container {
  width: 31px;
  height: 16px;
}

.switch-container:hover {
  cursor: pointer;
}

.switch-container--withShadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
