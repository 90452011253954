.flex {
  display: flex;
}

.usage__container {
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
  border-radius: 1px;
  padding: 1rem;
}

.usage__container hr {
  border: 1px solid #f2f2f2;
}

.usage__title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.usage__description {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #6d859e;
}

.usage__value {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #071629;
}
