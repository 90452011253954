.profile > .box {
  margin-top: 1rem;
}

.profile > .box:first-child {
  margin-top: 0;
}

.profile__password > form {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0 1rem;
}

.profile__password--error {
  color: var(--bad-color);
  align-self: center;
}

.profile__form__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0 1rem;
  align-items: center;
}

.profile__account__edit {
  width: 50px;
  justify-self: flex-end;
  grid-column: 5;
}

.profile__account__cancel {
  grid-column: 4;
  justify-self: flex-end;
}

.profile__account__update {
  grid-column: 5;
}

.profile__company__cancel {
  grid-column: 4;
  justify-self: flex-end;
}

.profile__company__update {
  grid-column: 5;
}

.profile__company__edit {
  width: 50px;
  justify-self: flex-end;
  grid-column: 5;
}

.profile__reports__form {
  display: flex;
  align-items: center;
}

.profile__reports__form > label {
  margin-left: 0.5rem;
}

.profile__form__container__status {
  color: var(--pixelme-color);
}

.profile__reports__edit {
  width: 50px;
  justify-self: flex-end;
  grid-column: 5;
}

.profile__reports__cancel {
  grid-column: 4;
  justify-self: flex-end;
}

.profile__reports__update {
  grid-column: 5;
}

.profile__form__container .checkbox-container {
  height: 0.8rem;
  width: 0.8rem;
}

.profile__form__container .checkbox-container:hover {
  cursor: pointer;
}

.profile__reports__form:hover label {
  cursor: pointer;
}
