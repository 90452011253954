.main-layout {
  height: calc(100vh - 61px);
  width: 100%;
}

.main-layout__content {
  grid-area: content;
  overflow: hidden;
}

.beta {
  color: #2e89e2;
  font-weight: 700;
  visibility: hidden;
}

div:hover > .beta {
  visibility: visible;
}
