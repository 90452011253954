.checkbox-container {
  display: flex;
  height: 100%;
  width: 100%;
  border: 0.05rem solid var(--pixelme-color);
  border-radius: 0.2rem;
  background: var(--white);
  padding: 1px;
}

.checkbox-container.checked > div {
  height: 100%;
  width: 100%;
  background: url('../../icons/checked.svg') no-repeat var(--white);
  background-size: 90%;
  background-position: center;
}
