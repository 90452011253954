.team-members__list__title span {
  font-size: var(--small-size);
  color: var(--text-muted);
  margin-left: 1rem;
}

.team-members__list__header {
  color: var(--text-muted);
}
.team-members__list__row {
  display: grid;
  grid-template-columns: 20rem 1fr 11rem 3rem;
  grid-gap: 0 1rem;
  height: 1.8rem;
  line-height: 1.8rem;
  align-items: center;
  margin: 0.5rem 0;
}

.team-members__list__row button {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.8rem;
}

.team-members__list__row--del {
  justify-self: end;
}

.team-members__list__row--odd {
  background: #f9f9f9;
}

.team-members__list__row--validated {
  justify-self: end;
}

.team-members__list__row--no {
  color: var(--bad-color);
}
