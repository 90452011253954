.coupons {
  margin-bottom: 1rem;
}
.coupon {
  padding: 0.5rem;
  margin: 0.5rem;
  color: var(--white);
  border-radius: 0.2rem;
  background-color: var(--pixelme-color);
  max-width: 220px;
  text-align: center;
}

.coupons__add {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.coupons__add button {
  margin-top: 1rem;
  max-width: 150px;
}

.revoked {
  font-size: 14px;
  color: var(--bad-color);
}
