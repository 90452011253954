.dismissable-info {
  display: flex;
  background: #edf5ff;
  padding: 16px 16px 16px 0;
  box-shadow:
    inset 3px 0 0 #0043ce,
    inset 0 1px 0 rgba(0, 67, 206, 0.4),
    inset 0 -1px 0 rgba(0, 67, 206, 0.4),
    inset -1px 0 0 rgba(0, 67, 206, 0.4);
}

.dismissable-info__icon {
  flex-basis: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dismissable-info__content {
  flex: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #161616;
}

.dismissable-info__content p {
  margin-bottom: 2px;
}

.dismissable-info__content p:last-child {
  margin-bottom: 0;
}

.dismissable-info__close {
  margin-top: 10px;
}

.dismissable-info__close-btn {
  cursor: pointer;
  text-decoration: underline;
}

.dismissable-info__learn-more {
  flex-basis: 85px;
  justify-content: right;
  align-items: center;
  display: flex;
}

.dismissable-info__learn-more a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0f62fe;
}
