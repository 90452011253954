@media screen and (max-height: 900px) {
  .with--dropdown .side-modal__content-under-title {
    height: 85vh;
  }
}

.side-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.side-modal.box {
  z-index: 101;
  width: 488px;
  height: 100vh;
  margin-left: auto;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  /* box-shadow: 0px 0px 4px 4px #eeeeee; */
  position: absolute;
  top: 0;
  right: 0;
}

.side-modal__content {
  padding: 0rem;
  text-overflow: ellipsis;
}

.side-modal__content-under-title {
  padding: 0 1rem 0;
  width: 100%;
  flex: 1;
  overflow: overlay;
  margin-bottom: -1px;
}

.side-modal__buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.side-modal__button {
  width: auto;
  margin: auto;
  padding: 0.1rem 1rem;
}

.side-modal__title__container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.side-modal__title {
  height: 47px;
  padding: 1rem;
  font-size: 16px;
  margin-top: 7px;
  margin-bottom: 10px;
  flex: 1;
}

.side-modal__close-button {
  width: 10px;
  margin-right: 25px;
  display: inline-block;
  align-self: center;
  align-content: center;
  align-items: center;
}
.side-modal__button {
  width: 160px;
  align-self: center;
  height: 34px;
  font-weight: 500;
}

.side-modal__button-container {
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 10px;
  display: flex;
}
.side-modal__button button:nth-child(2) {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .side-modal.box {
    display: block !important;
    height: calc(100vh - 50px) !important;
  }
}

.side-modal__footer {
  border-top: 1px solid #f2f2f2;
  width: 100%;
  text-align: center;
  padding-top: 23px;

  padding-bottom: 23px;
  min-height: 80px;
}
