.dropdowns__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdowns__header a {
  font-size: 10px;
}

.dropdowns__add {
}

.dropdowns__tags input {
  width: 100%;
  height: 2rem;
  margin: 0;
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 0.5rem;
}

.dropdowns__tags--disabled {
  color: var(--text-muted);
}

.dropdowns__tags li {
  text-transform: capitalize;
}

.dropdowns__domains {
  height: 100%;
}

.dropdowns__pixels {
  height: 100%;
  flex: 0;
}

.dropdowns__tags {
  height: 100%;
}

.dropdowns__pixels .pixel {
  margin-right: 0.4rem;
  flex-shrink: 0;
}

.dropdown_inline {
  margin-top: 10px;
}
