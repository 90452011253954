.box-switcher {
  display: flex;
  color: var(--text-muted);
}

.box-switcher:hover {
  cursor: pointer;
}

.box-switcher__toggle {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
