.plans {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.plans__period-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.plans__period-switcher > * {
  margin-right: 0.5rem;
}

.plans__type-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.plans__type-switcher .notice {
  /* color: var(--text-muted); */
  font-size: 9px;
}

.plans__type-switcher .muted {
  color: var(--text-muted);
}

.plans__type-switcher--clickable {
  cursor: pointer;
  color: var(--pixelme-color);
}

.plans__type-switcher--clickable.disabled:hover {
  cursor: not-allowed;
}

.plans__text-medium-font-size {
  font-size: medium;
}

.plans__text-long {
  margin-bottom: 1rem;
}

.plans__button-bottom {
  bottom: 20px;
  position: absolute;
  width: 85%;
}

.plans__period-switcher .notice {
  /* color: var(--text-muted); */
  font-size: var(--small-size);
}

.plans__period-switcher .muted {
  color: var(--text-muted);
}

.plans__period-switcher--clickable {
  cursor: pointer;
  color: var(--pixelme-color);
}

.plans__period-switcher--clickable.disabled:hover {
  cursor: not-allowed;
}

.plans__content {
  position: relative;
  overflow: auto;
  text-align: center;
}

.plans__content__block {
  display: inline-flex;
  padding-bottom: 20px;
}

.plans__content__footer {
  color: var(--text-muted);
  text-align: center;
  margin: 1em 0 2em;
}

.plans__button_swicth_pan {
  border-radius: 0%;
  min-width: 220px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 600;
  border: 1px solid var(--pixelme-color);

  color: var(--pixelme-color);
  background-color: var(--white);
}

.plans__button_swicth_pan--active {
  background: var(--pixelme-color);
  color: var(--white);
  pointer-events: none;
}

.plans__content__footer__contact {
  color: var(--pixelme-color);
  cursor: pointer;
  padding: 1em 0 2em;
}

.plans__plans__container {
  margin: 0;
  margin-bottom: 20px;
  position: relative;
}

.plans__plans__container:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.plans__footer_call_us {
  position: absolute;
  bottom: 0;
  margin-top: 5px;
  font-size: 15px;
}

.plans__plan_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.plans__plan {
  background-color: var(--white);
  padding: 1rem 1.3rem;
  height: 100%;
  width: 13vw;
  min-width: 275px;
  border: var(--input-border);
}

.plans__plan__header {
  border-radius: 0.3rem 0.3rem 0 0;
  padding-top: 16px;
  background-color: var(--pixelme-color);
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); */
  height: 50px;
  top: -40px;
}

.plans__plan.selected {
  border: 1px solid var(--pixelme-color);
}

.plans__plan__title {
  font-size: var(--title-size);
  font-weight: normal;
  text-align: center;
  height: 2em;
}

.plans__plan__amount {
  font-size: 2.2vw;
  font-weight: 200;
}
.plans__plan_light_chip {
  display: inline-block;
  background: #2ec1b3;
  border-radius: 7px;
  color: white;
  font-weight: 500;
  font-size: 0.6vw;
  padding: 0 10px;
  text-align: center;
}

@media screen and (max-width: 980px) {
  .plans__plan_light_chip {
    width: 100%;
    margin-left: 0;
  }
}

.plans__plan__amount_business {
  font-size: 1.8vw;
  font-weight: 150;
  margin-top: 9px;
}

.plans__plan__amount > span {
  font-size: var(--small-size);
  color: var(--text-muted);
}

.plans__plan__info_billed {
  font-size: 9px;
  position: absolute;
  top: 24px;
  white-space: nowrap;
  color: var(--text-muted);
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__amount > span {
  color: var(--white);
}

.plans__plan__quote {
  font-size: 22px;
  font-weight: 200;
  min-height: 43px;
}

.plans__plan__subtitle {
  display: block;
  text-align: center;
  color: var(--text-muted);
  font-weight: 200;
  margin-top: 10px;
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__subtitle {
  color: var(--white);
}

.plans__plan__subtitle--bold {
  color: var(--pixelme-color);
  font-weight: 600;
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__subtitle--bold {
  color: var(--white);
}

.plans__plan .separator {
  margin-top: 0.5rem;
  height: 0;
  /* padding-top: 1rem; */
}

.plans__content .plans__plans__container:last-child .plans__plan {
  background-color: var(--pixelme-color);
  color: var(--white);
  border-color: var(--pixelme-color);
}

.plans ul {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 300;
  color: var(--text-muted);
}

.plans ul > li {
  height: 2.2rem;
}

.plans__content .plans__plans__container:last-child .plans__plan ul {
  color: var(--white);
}

.plans li.plans__plan__limit-item {
  list-style: none;
  padding: 0.6rem 0;
}

.plans li.plans__plan__feature-item {
  list-style: none;
  padding: 0.6rem 0;
  color: var(--text-color);
  text-align: left;
}

.plans .plans__text-center {
  text-align: center;
}

.plans .icon-included {
  display: inline-block;
  background-image: url('../../../icons/check-blue.svg');
  width: var(--small-size);
  height: var(--small-size);
  background-repeat: no-repeat;
}

.plans .icon-excluded {
  display: inline-block;
  background-image: url('../../../icons/close.svg');
  width: var(--small-size);
  height: var(--small-size);
  background-repeat: no-repeat;
}

.plans__content .plans__plans__container:last-child .plans__plan .icon-included {
  display: inline-block;
  background-image: url('../../../icons/check-white.svg');
  width: var(--small-size);
  height: var(--small-size);
  background-repeat: no-repeat;
}

.plans li.plans__plan__limit-item-included {
  list-style: none;
  padding: 0.6rem 0 0.6rem 1rem;
  background: url('../../../icons/check-blue.svg');
  background-repeat: no-repeat;
  background-position: left center;
}

.plans li.plans__plan__limit-item-included {
  list-style: none;
  padding: 0.6rem 0 0.6rem 1rem;
  background: url('../../../icons/check-blue.svg');
  background-repeat: no-repeat;
  background-position: left center;
}

.plans__content .plans__plans__container:last-child .plans__plan li.plans__plan__limit-item-included {
  background: url('../../../icons/check-white.svg');
  background-repeat: no-repeat;
  background-position: left center;
}

.plans li.plans__plan__limit-item-excluded {
  opacity: 0.3;
  list-style: none;
  padding: 0.6rem 0 0.6rem 1rem;
  background: url('../../../icons/close.svg');
  background-repeat: no-repeat;
  background-position: left center;
}

.plans__plan__limit-item--bold {
  font-weight: bold;
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__limit-item span {
  color: var(--white);
}

.plans__plan__limit-item span {
  color: var(--pixelme-color);
  font-weight: bold;
}

.plans__plan__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  flex-direction: column;
  row-gap: 1em;
}

.plans__plan__button > * {
  min-width: 100% !important;
  font-weight: 500;
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__button > * {
  width: 100%;
  color: var(--pixelme-color);
  background-color: var(--white);
}

.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__button > *:hover {
  background-color: rgb(192, 220, 246);
  text-decoration: none;
}
.plans__content .plans__plans__container:last-child .plans__plan .plans__plan__button > *:visited {
  text-decoration: none;
}

.plans__plan__plan-name {
  font-style: normal;
  font-weight: 300;
  font-size: 1.7vw;
  line-height: 33px;
}

.plans .modal {
  width: 350px;
}

.plans__agency--muted {
  color: var(--text-muted);
}

.plans__plan__trial-modal {
  color: var(--text-color);
}

.plans__plan__trial-modal .modal__title {
  font-size: 16px;
}

.plans__plan__placeholder {
  margin: 1rem;
  height: 460px;
}

.plans__plan__placeholder .placeholder {
  height: 40px;
}
.plans__plan__placeholder .placeholder__line {
  width: 100%;
}

.plans__content {
  display: flex;
  justify-content: center;
}

.plans__content__downgrade-free-modal .modal__title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.plans__content__downgrade-free-modal .modal__title svg {
  margin-right: 10px;
}
.plans__content__downgrade-free-modal .modal__content {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 30px 50px 20px 50px;
}

.plans__content__downgrade-free-modal .modal__content ul {
  margin: 10px 0 0 0;
}

.plans__content__downgrade-free-modal .modal__content ul > li {
  padding: 5px 0 5px 1rem;
}
.plans__plan_header {
  min-height: 250px;
}
