.drop-down-menu {
  display: flex;
  align-items: center;
  --drop-down-menu-padding: 1rem;
}

.drop-down-menu__icon:hover {
  cursor: pointer;
}

.drop-down-menu__title {
  margin: 0px 0.8rem;
  cursor: pointer;
  width: max-content;
}

.drop-down-menu__mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.drop-down-menu__content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 15rem;
  background: var(--white);
  z-index: 9000;
  border: solid var(--border-size) #e5e5e5;
  color: var(--text-muted);
  box-shadow: 0px 0px 4px 4px #eeeeee;
  border-radius: 0.2rem;
  max-height: 500px;
  font-size: 13px;
}

.drop-down-menu__content.layout-with-header {
  top: 104px;
}

.drop-down-menu__content > * {
  z-index: 101;
}
.drop-down-menu__accounts {
  border-top: var(--separator);
  overflow: auto;
}
.drop-down-menu__account {
  min-height: 51px;
  padding: var(--drop-down-menu-padding);
  display: flex;
  justify-content: space-between;
}
.drop-down-menu__account__name {
  color: var(--text-color);
}

.drop-down-menu__accounts > .drop-down-menu__account > a {
  color: var(--text-muted);
}

.drop-down-menu__accounts > .drop-down-menu__account > a:hover {
  text-decoration: none;
}

.drop-down-menu__accounts > .drop-down-menu__account:hover {
  background: var(--pixelme-light-color);
}

.drop-down-menu__actions {
  min-height: 70px;
  width: 100%;
  border-top: var(--separator);
  padding: var(--drop-down-menu-padding);
  display: flex;
  justify-content: space-between;
}

.drop-down-menu__actions button {
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  padding: 0 12px;
}

.drop-down-menu__content .input-container {
  padding: var(--drop-down-menu-padding);
}
