.provider-box {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.product-provider-accounts-status {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.product-provider-accounts-status__badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 6px;
  gap: 6px;
  background: #ebf9ef;
  border-radius: 16px;
  color: #2fc25b;
}

.product-provider-accounts-status__badge svg circle {
  fill: #2fc25b;
}

.product-provider-accounts-status--error .product-provider-accounts-status__badge {
  background: #fcece9;
  color: #d93f21;
}

.product-provider-accounts-status--error .product-provider-accounts-status__badge svg circle {
  fill: #d93f21;
}

.product-provider-accounts-status__description {
  color: #595959;
}

.product-provider-accounts-status--error .product-provider-accounts-status__description {
  display: none;
}

.alert__link {
  color: #2fc25b;
  cursor: pointer;
}
