.keyword:hover .floating-keywork-cell-action {
  margin-left: 0;
  position: relative;
}

.keyword:hover .floating-keywork-cell-action-container {
  opacity: 1;
  width: fit-content;
  height: 80%;
  top: 5px;
  padding-right: 1em;
  padding-left: 1em;
  border-left: 1px solid #eee;
  filter: drop-shadow(0px 0px 8px #00000081);
  z-index: 3;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-box-orient: vertical;
  -webkit-transition: 200ms -webkit-transform;
  left: 80%;
}

.keyword:hover
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--pixelme-color);
  transition: background-color 0.5s ease;
}
