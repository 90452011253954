.query-builder {
  width: 100%;
}

.query-builder__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.query-builder__breadcrumb > a {
  color: var(--text-muted);
}

.query-builder__details {
  border-top: var(--separator);
  margin-top: 1rem;
  padding: 1rem 0 0 0;
}

.query-builder__details > div {
  margin: 0.5rem 0;
}

.query-builder__details label {
  display: inline-block;
  width: 160px;
}
.query-builder__details .query-builder__details--muted {
  color: var(--text-muted);
}

.query-builder .bp3-popover-target {
  width: auto;
}

.query-builder #period .drop-down__content {
  min-width: 0;
}

.query-builder .date-picker__button:hover {
  border-color: var(--pixelme-color) !important;
}

.query-builder .date-picker__button {
  background: none !important;
  box-shadow: none !important;
}
.query-builder .date-picker__button {
  border: var(--input-border);
}

.query-builder .date-picker__button .bp3-button-text {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.query-builder .date-picker__button .bp3-button-text span {
  margin-left: 10px;
}
