.notices-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 600;
}
.notices {
  background: var(--white);
  margin: 1rem;
  padding: 0;
  max-width: 550px;
}
.notices > li {
  display: flex;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  list-style: none;
  border: solid var(--border-size) #e5e5e5;
  border-radius: 0.2rem;
  box-shadow: 0px 4px 2px 2px #eeeeee;

  margin-bottom: 1rem;
}

.notices > li:last-child {
  margin-bottom: 0;
}

.notices svg.icon-type {
  margin-right: 0.5rem;
}

.notices svg.cross {
  margin-left: 0.5rem;
  cursor: pointer;
}

.notice__icon {
  display: flex;
  align-items: center;
  flex: 0 0;
}
.notice__cross {
  display: flex;
  align-items: top;
  flex: 0 0;
}
.notice__message {
  flex: 1 1;
}
