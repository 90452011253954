.card-details__current-card {
  margin-top: 0.5rem;
  display: flex;
}

.card-details__current-card > * {
  margin-right: 1rem;
}
.card-details__current-card > *:last-child {
  margin-right: 0;
}

.card-details .settings-box__content .placeholder {
  margin: 1rem 0;
}

.card-details .settings-box__content .placeholder__line {
  width: 300px;
}

.error-message {
  margin-top: 0.5rem;
  color: red; 
}