.onboarding {
  text-align: center;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding h1 {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 25px;
  margin-top: 2rem;
}

.onboarding p {
  font-family: var(--font-family);
  font-size: 14px;
  margin-bottom: 2rem;
}

.onboarding__button-container {
  width: 220px;
  align-self: center;
  height: 41px;
  font-weight: 500;
}
.onboarding__container_button {
  display: flex;
}
