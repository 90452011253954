.page-setting__input {
  margin-top: 10px;
  width: 281px;
  height: 32px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
}

.page-setting__input input {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: none;
  padding: 0;
  padding-left: 4px;
  margin: 0;
}

.page-setting__input input::placeholder {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #e5e5e5;
}

.page-setting__block {
  background: white;
  padding: 1rem;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
}

.page-setting__block hr {
  border: none;
  border-top: 0.3px solid #f2f2f2;
  width: 100%;
}

.page-setting__block p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 19px;
  color: #071629;
}

.page-setting__input-title {
  margin-top: 10px;
  width: 400px;
  height: 32px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
}

.page-setting__input-title::placeholder {
  color: #b0b0b0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
