.drop-down__container {
  height: 1.8rem;
  width: auto;
  --drop-padding-x: 0.8rem;
}

.drop-down__container--auto {
  width: auto;
}

.drop-down__container--xs {
  width: 4rem;
}

.drop-down__container--xs .drop-down__content--right {
  transform: translate(calc(4rem - 100%));
}

.drop-down__container--small {
  width: 7.5rem;
}
.drop-down__container--small .drop-down__content--right {
  transform: translate(calc(7.5rem - 100%));
}

.drop-down__container--medium,
.bp3-button.drop-down {
  width: 160px;
}

.drop-down__container--medium .drop-down__content--right {
  transform: translate(calc(11.5rem - 100%));
}
.drop-down__container--large {
  width: 15rem;
}
.drop-down__container--large .drop-down__content--right {
  transform: translate(calc(15rem - 100%));
}

.drop-down__container--xlarge {
  width: 20rem;
}
.drop-down__container--xlarge .drop-down__content--right {
  transform: translate(calc(20rem - 100%));
}

.drop-down__title {
  width: 100%;
  margin-right: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  flex: 1;
}

.drop-down {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 0 var(--drop-padding-x);
  cursor: pointer;
  background: white;
}

.drop-down.drop-down--disabled {
  cursor: not-allowed;
}

.drop-down.drop-down--disabled:hover {
  border: var(--input-border);
}

.drop-down:hover {
  border-color: var(--pixelme-color);
}

.drop-down--open {
  border-color: var(--pixelme-color);
}

.drop-down.drop-down--no-border {
  border: 0px;
}

div.drop-down__mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.dropdowns__tags li.drop-down__search-info {
  text-transform: none;
  cursor: initial;
  background: none;
  font-size: 10px;
}
.dropdowns__tags li.drop-down__search-info > span {
  color: var(--text-muted);
  text-transform: capitalize;
}

.drop-down__content--scrollable--true {
  max-height: 200px;
  overflow: auto !important;
}

.bp3-popover-target .bp3-button.drop-down {
  background-color: transparent !important;
  border: 1px solid #eee;
  box-shadow: none;
  display: flex;
  color: rgba(4, 21, 33, 0.8);
}

.bp3-popover-target .bp3-button.drop-down .bp3-button-text {
  display: flex;
  align-self: center;
  align-items: center;
  color: rgba(4, 21, 33, 0.8);
  width: 100%;
}

.bp3-menu {
  max-height: 400px;
  overflow: auto;
  padding: 0 !important;
}

.bp3-icon.bp3-icon-search svg {
  padding: 2px;
  margin-top: 2px;
}

.bp3-popover-content .bp3-input-group .bp3-input {
  padding-right: 10px;
  margin: 2px;
  width: calc(100% - 4px);
}
