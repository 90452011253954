.composer {
  width: 100%;
  margin-top: -8px;
  font-size: var(--small-size);
}
.composer .input {
  padding: 7px 10px;
}

.composer-container {
  display: flex;
  align-items: center;
  /* width: 20rem; */
}

.composer__line {
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.composer__line__after_title {
  display: flex;
  justify-content: space-between;
}

.composer__line__after_title li {
  display: block;
}
.composer__line_grid {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 17px;
  display: grid;
}

.composer__line_grid .drop-down {
  margin-top: 0.5rem;
}

.toggle-modal-create-link {
  width: 10px !important;
  margin-top: 1px;
  margin-right: 5px;
  cursor: pointer;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: transform 0.5s;
}

.toggle-modal-create-link-active {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}

.composer__url {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: left;
}

.composer__dropdowns > * {
  margin-right: 5px;
}

.composer__dropdowns > *:last-child {
  margin-right: 5px;
}
.composer__url > label {
  margin: 5px 0;
}

.composer__line .drop-down {
  margin-top: 0.5rem;
}

.composer__pixels__newpixel {
  margin-left: 2rem;
}

.composer__line.composer__utms {
  justify-content: flex-start;
}

/* .composer__line.composer__utms > div:last-child {
   margin-left: 30px; 
} */

.composer__line.composer__utms > div:last-child input {
  margin-top: 10px;
  width: 180px;
}

.composer__line.composer__utms > div:last-child > * {
  margin-top: 20px;
}

.composer__line .composer__rotator {
  justify-content: flex-start;
  padding: 0.5rem 0;
}

.composer__line .composer__rotator > * {
  color: var(--text-color);
}

.composer__line .composer__rotator > *:last-child {
  width: 50%;
}

.composer__line .composer__rotator li {
  display: block;
}

.composer__line .composer__rotator label {
  width: 100%;
}

.composer__line .composer__rotator .title-with-delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.composer__line .composer__rotator .title-with-delete .delete-icon {
  width: 1rem;
  height: 1rem;
  /* margin-left: 1rem; */
}

.composer__line .composer__rotator .title-with-delete .delete-icon:hover {
  cursor: pointer;
}

.composer__line__buttons {
  justify-content: flex-end;
}

.composer__line__buttons button {
  padding: 0.5rem 1.8rem;
}

.composer__title {
  width: 100%;
}

.composer--edit .composer__line {
  height: 2rem;
}

.composer--edit .composer__line:first-child {
  border-bottom: var(--separator);
}

.composer__shorten {
  cursor: pointer;
  margin-right: 0.5rem;
  color: var(--pixelme-color);
}

.composer__shorten--edit {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  color: var(--pixelme-color);
  flex: 1 1;
}

.composer__error {
  font-weight: normal;
  color: var(--bad-color);
}

.composer--edit .dropdowns__pixels {
  margin-bottom: 0.5;
}

.composer--edit .pixels-list {
  justify-content: flex-end;
}

.composer--edit #title {
  width: 100%;
}

.composer__line .dropdowns__tags {
  margin-top: 8px;
}
