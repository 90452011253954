.consent {
  display: flex;
  align-items: center;
  flex: 1 1;
  margin-right: 1em;
}
.consent > * {
  margin-right: 0.5rem;
}

.consent > *:last-child {
  margin-right: 0;
}

.consent > label {
  cursor: pointer;
  white-space: nowrap;
}

.consent .checkbox-container {
  flex: 0 0 0.8rem;
  height: 0.8rem;
  cursor: pointer;
}

.consent--disabled .checkbox-container {
  border-color: var(--text-muted);
  cursor: not-allowed;
}

.consent--disabled > label {
  color: var(--text-muted);
  cursor: not-allowed;
}

.disable-tracking {
  color: var(--text-muted);
  display: flex;
  align-items: center;
  flex: 1 1;
}
.disable-tracking > * {
  margin-right: 0.5rem;
}

.disable-tracking > *:last-child {
  margin-right: 0;
}

.disable-tracking > label {
  cursor: pointer;
  white-space: nowrap;
}

.disable-tracking .checkbox-container {
  border-color: var(--text-muted);
  flex: 0 0 0.8rem;
  height: 0.8rem;
  cursor: pointer;
}

.disable-tracking .checkbox-container.checked > div {
  background: url('../../icons/checked-grey.svg') no-repeat var(--white);
}
