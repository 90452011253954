.drop-down__container {
  height: 1.8rem;
  width: auto;
  --drop-padding-x: 0.8rem;
}

.drop-down__container--auto {
  width: auto;
}

.drop-down__container--xs {
  width: 4rem;
}

.drop-down__container--xs .drop-down__content--right {
  transform: translate(calc(4rem - 100%));
}

.drop-down__container--small {
  width: 7.5rem;
}

.drop-down__container--small .drop-down__content--right {
  transform: translate(calc(7.5rem - 100%));
}

.drop-down__container--medium {
  width: 160px;
}

.drop-down__container--medium .drop-down__content--right {
  transform: translate(calc(11.5rem - 100%));
}

.drop-down__container--large {
  width: 15rem;
}

.drop-down__container--large .drop-down__content--right {
  transform: translate(calc(15rem - 100%));
}

.drop-down__container--xlarge {
  width: 20rem;
}

.drop-down__container--xlarge .drop-down__content--right {
  transform: translate(calc(20rem - 100%));
}

.drop-down__title {
  width: 100%;
  margin-right: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  flex: 1;
}

.drop-down {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 0 var(--drop-padding-x);
  cursor: pointer;
  background: white;
}

.drop-down.drop-down--disabled {
  cursor: not-allowed;
}

.drop-down.drop-down--disabled:hover {
  border: var(--input-border);
}

.drop-down:hover {
  border-color: var(--pixelme-color);
}

.drop-down--open {
  border-color: var(--pixelme-color);
}

.drop-down.drop-down--no-border {
  border: 0px;
}

div.drop-down__mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.dropdowns__tags li.drop-down__search-info {
  text-transform: none;
  cursor: initial;
  background: none;
  font-size: 10px;
}

.dropdowns__tags li.drop-down__search-info > span {
  color: var(--text-muted);
  text-transform: capitalize;
}

.drop-down__content--scrollable--true {
  max-height: 200px;
  overflow: auto !important;
}

.bp3-popover-target {
  width: 160px;
}

.bp3-tag {
  width: 100%;
  height: 30px;
  background-color: transparent !important;
  color: black !important;
}

.bp3-multi-select-tag-input-input {
  border: 1px solid #eee !important;
  padding: 2px !important;
}

.bp3-text-overflow-ellipsis.bp3-fill {
  line-height: 18px;
  font-size: 12px;
}

.bp3-menu-item-label {
  font-size: 12px !important;
}

/*.bp3-input-ghost:focus, .bp3-input.bp3-active-ghost {*/
/*    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);*/
/*}*/

/*div.bp3-input.bp3-tag-input.bp3-active.bp3-multi-select {*/
/*    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2) !important;*/
/*}*/

.bp3-input .icon-for-input {
  margin: 0 !important;
  margin-right: -2px !important;
  margin-top: -5px !important;
  color: #5c7080;
  font-weight: 600;
  font-size: 16px !important;
  padding: 5px;
  height: 26px;
}

.bp3-tag-input .bp3-tag-input-values:empty::before,
.bp3-tag-input .bp3-tag-input-values > :last-child {
  margin-right: 0;
  border-radius: 3px;
  margin-left: -21px;
  padding-left: 25px !important;
}

.bp3-menu {
  max-height: 400px;
  overflow: auto;
}

.bp3-icon.bp3-icon-search svg {
  padding: 2px;
}

span.bp3-icon:empty {
  font-size: 13px !important;
  margin-top: -1px !important;
}
