.endtrial .endtrial-mask {
  position: fixed;
  top: 0;
  left: 4rem;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: auto;
  background: rgba(255, 255, 255, 0.8);
}

.endtrial__modal {
  font-size: 12px;
  /* position: relative; */
  padding: 2rem;
  z-index: 101;
  width: 486px;
  margin: auto;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 4px #eeeeee;
}

.endtrial__modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.endtrial__modal__content {
  margin: 20px 0;
  width: 289px;
  text-align: center;
}

.endtrial__modal__footer {
  width: 340px;
  display: flex;
  justify-content: space-between;
}

.endtrial__modal__footer .button {
  width: 160px;
}
