/* Section */
.suggestions-section {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #f2f2f2;
}

.suggestions-section__title {
  font-size: 14px;
  color: #262626;
  font-weight: 600;
  margin-bottom: 10px;
}

.suggestions-section__title-help {
  font-size: 13px;
  color: #0e32f5;
  margin: 0 1em 0 0.5em;
}

.suggestions-section__title-edit {
  color: #0e32f5;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
}

/* Product Info */
.suggestions-product-info {
  display: flex;
  align-items: center;
}

.suggestions-product-info__img {
  margin: 5px 1em 5px 5px;
  width: 36px;
  height: 36px;
  background-size: contain;
  background-position: center center;
}

.suggestions-product-info__asin {
  color: #595959;
}

/* Competitors List */
.competitors-list {
  display: flex;
  gap: 2em;
}
