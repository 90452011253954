.delete-item:hover {
  cursor: pointer;
}

.delete-item.delete-item--disabled:hover {
  cursor: not-allowed;
}

.delete-item:hover .delete-item__icon {
  fill: var(--bad-color);
}

.delete-item.delete-item--disabled:hover .delete-item__icon {
  fill: #e5e5e5;
}
