.ad-suggestions {
  margin-bottom: 1em;
  min-width: 400px;
}

.ad-suggestions__container {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 15px;
}

.ad-suggestions-section:not(:last-child) {
  margin-bottom: 15px;
}

.ad-suggestions-section__title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #262626;
}

.ad-suggestions-section__title small {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #000000;
}

.ad-suggestions-section__container {
}

.ad-suggestions__btn {
  background: #f2f2f2;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  color: #071629;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  padding: 2px 5px;
  display: inline-block;
  margin: 4px 8px 4px 0;
}

.ad-suggestions__btn--selected {
  color: #ffffff;
  background: #096dd9;
  border: 1px solid #096dd9;
}
