.error_modal__title {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  color: #071629;
  margin: 0;
  padding: 1em 0;
}

.error_modal__text {
  font-family: var(--font-family);
  font-size: 12px;
  text-align: center;
  color: #071629;
}

.error_modal__image {
  margin-right: 1rem;
}

.error_modal__title_container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  justify-content: center;
  align-items: center;
}

.error_modal__button {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  width: 133px;
  height: 32px;
  margin-top: 1rem;
  font-size: 12px;
  color: #ffffff;
}
