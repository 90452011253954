.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  --animation: placeholder-animation 2s infinite;
}

.placeholder.motion-less {
  --animation: none;
}

.placeholder__group > * {
  margin-top: 0.5rem;
}
.placeholder__group > *:first-child {
  margin-top: 0;
}
.placeholder__line {
  width: 10rem;
  height: 1rem;
  border-radius: 0.2rem;
  animation: var(--animation);
}
.placeholder.motion-less .placeholder__line {
  background: var(--bg-color);
}

.placeholder__sbox {
  width: 5rem;
  height: 2rem;
  border-radius: 0.2rem;
  animation: var(--animation);
}

.placeholder.motion-less .placeholder__sbox {
  background: var(--bg-color);
}

.redirect-placeholder {
  border-top: var(--separator);
}

.redirect-placeholder__square {
  width: 0.8rem;
  height: 0.8rem;
  animation: var(--animation);
  border-radius: 0.2rem;
  margin-right: 0.5rem;
}

.placeholder.motion-less .redirect-placeholder__square {
  background: var(--bg-color);
}

.redirect-placeholder__circle {
  width: 0.8rem;
  height: 0.8rem;
  animation: var(--animation);
  border-radius: 1rem;
  margin: 0.2rem;
}

.placeholder.motion-less .redirect-placeholder__circle {
  background: var(--bg-color);
}

.redirect-placeholder__line--small {
  height: 50% !important;
  width: 5rem;
  animation: var(--animation);
  border-radius: 0.6rem;
}

.placeholder.motion-less .redirect-placeholder__line--small {
  background: var(--bg-color);
}

.redirect-placeholder__line--xsmall {
  height: 50% !important;
  width: 4.5rem;
  animation: var(--animation);
  border-radius: 0.6rem;
}

.placeholder.motion-less .redirect-placeholder__line--xsmall {
  background: var(--bg-color);
}

.redirect-placeholder__line--xxsmall {
  height: 50% !important;
  width: 4rem;
  animation: var(--animation);
  border-radius: 0.6rem;
}

.placeholder.motion-less .redirect-placeholder__line--xxsmall {
  background: var(--bg-color);
}

.redirect-placeholder__line--xxxsmall {
  height: 50% !important;
  width: 3.5rem;
  animation: var(--animation);
  border-radius: 0.6rem;
}

.placeholder.motion-less .redirect-placeholder__line--xxxsmall {
  background: var(--bg-color);
}

.redirect-placeholder__line--large {
  height: 50% !important;
  width: 15rem;
  animation: var(--animation);
  border-radius: 0.6rem;
}

.placeholder.motion-less .redirect-placeholder__line--large {
  background: var(--bg-color);
}

.redirect-placeholder.placeholder {
  display: block;
  height: auto;
  width: auto;
  padding: 0.5rem;
}

.redirect-placeholder > .redirect__line > * {
  display: flex;
  align-items: center;
}

.redirect-placeholder:first-child {
  border-top: none;
}

.placeholder__svg > path {
  animation: svg-animation 2s infinite;
}

.placeholder__svg.motion-less > path {
  animation: none;
  stroke: var(--bg-color);
}

@keyframes svg-animation {
  0% {
    stroke: var(--bg-color);
  }
  50% {
    stroke: #e9e9e9;
  }
  100% {
    stroke: var(--bg-color);
  }
}

@keyframes placeholder-animation {
  0% {
    background: var(--bg-color);
  }
  50% {
    background: #e9e9e9;
  }
  100% {
    background: var(--bg-color);
  }
}

@keyframes placeholder-border-animation {
  0% {
    border-left: 3px solid var(--bg-color);
    border-bottom: 3px solid var(--bg-color);
  }
  50% {
    border-left: 3px solid #e9e9e9;
    border-bottom: 3px solid #e9e9e9;
  }
  100% {
    border-left: 3px solid var(--bg-color);
    border-bottom: 3px solid var(--bg-color);
  }
}

.placeholder.placeholder__line-graph {
  display: block;
}

.placeholder.placeholder__line-graph .placeholder__line {
  margin-bottom: 1rem;
}
.placeholder__graph {
  display: flex;
  height: 80%;
  width: 90%;
  animation: placeholder-border-animation 2s infinite;
  margin: 0 0 1rem 0.5rem;
}

.placeholder__graph > svg {
  margin: auto;
  transform: scale(2);
  height: 100%;
}
