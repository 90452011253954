.settings-box {
  position: relative;
}

.settings-box__header {
  margin-bottom: 1rem;
}

.settings-box__content {
  font-size: var(--small-size);
}

.settings-box .separator {
  height: 0.5rem;
}

.no-padding {
  padding: 0;
}
