.create-redirect__button {
  width: 160px;
  align-self: center;
  height: 34px;
  font-weight: 500;
}

.create-redirect__button-container {
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 10px;
  display: flex;
}
.create-redirect__button-container button:nth-child(2) {
  margin-left: 10px;
}

.create-redirect__modal .modal__content {
  padding: 0.5rem 3px 0;
  width: 100%;
}

.create-redirect__modal .editable-field {
  text-align: left;
}

.side-modal__footer .card-required-message {
  color: var(--bad-color);
  cursor: pointer;
  text-decoration: underline;
}

.card-required-message-link {
  padding-bottom: 2%;
}
