.domains > .box {
  margin-top: 1rem;
}
.domains > .box:first-child {
  margin-top: 0;
}

.domains button,
input {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.8rem;
}

.domains__add form {
  display: flex;
  margin: 0.5rem 0;
}

.domains__add form > * {
  margin-right: 1rem;
  height: 1.8rem;
}

.domains__add button {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.8rem;
}

.domains__add input {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.8rem;
}

.domains__add .modal {
  width: 400px;
  height: 250px;
}

.domains__modal--error {
  margin-top: 2rem;
  color: var(--text-muted);
  font-size: var(--normal-size);
}

input#domain {
  min-width: 300px;
}

.domains__add form > *:last-child {
  margin-right: 0;
}

.domains__add__text {
  margin-top: 2rem;
  width: 400px;
}
.domains__add__text--muted {
  color: var(--text-muted);
}
.domains__list__header span {
  font-size: var(--small-size);
  color: var(--text-muted);
  margin-left: 1rem;
}

.domain {
  border-top: var(--separator);
  padding: 1rem 0;
}
.domain input {
  max-width: 300px;
}

.domain:first-child {
  border-top: none;
  padding-top: 0.5rem;
}

.domain:last-child {
  padding-bottom: 0.5rem;
}
.domain__header {
  display: flex;
  /* align-content: center; */
  justify-content: space-between;
}

.domain__header__name {
  font-size: var(--normal-size);
}

.domain__header__name > span {
  color: var(--pixelme-color);
}

.domain__details > * {
  margin: 0.5rem 0;
}

.domain__details__label {
  color: var(--text-muted);
}

.domain__header__buttons {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.domain__details__valid__buttons {
  display: flex;
  justify-content: space-between;
  height: 1.8rem;
  margin-top: 1rem;
}

.domain .modal {
  width: 348px !important;
  height: auto !important;
}

.domain .modal span {
  color: var(--pixelme-color);
}

.domain__waiting {
  font-family: var(--font-family);
  font-size: 12px;
  color: #2ec1b3;
  margin-left: 1rem;
}
