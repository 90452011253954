.facet-box {
  overflow: hidden;
}
.facet-box__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
}

.facet-box__title {
  display: flex;
  align-items: center;
}
.facet-box__title svg {
  margin-left: 5px;
}

.facet-box__title--clickable {
  cursor: pointer;
}

.facet-box__content {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  padding: 1rem 0 2rem 0;
  border-top: var(--separator);
  overflow: auto;
}

.facet-box__content .placeholder > * {
  width: 80%;
  margin-top: 1rem;
}

.facet-box .modal.box {
  width: 460px;
}
